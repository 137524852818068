import { Entities } from '@inwink/entities/entities';
import { getDb } from '../../../data';
import * as Notifications from './notifications';
import { States } from '../../../services/services';

export function getUserRepository(
    event: States.IEventState,
    userid: string,
    options?: any,
    disableRead?: boolean
): States.IPersonDataStore {
    const userData = newUserData(event.eventid, userid, options);

    userData.ready = new Promise<States.IPersonDataStore>((dbComplete, dbError) => {
        if (disableRead) {
            setUserNotificationData(event, userData, dbComplete, dbError);
        } else {
            userData.db.loadDatabase(null, true).then(() => {
                setUserNotificationData(event, userData, dbComplete, dbError);
            });
        }
    });

    return userData;
}

function setUserNotificationData(event: States.IEventState, 
    userData: States.IPersonDataStore, 
    dbComplete: (thenableOrResult?: States.IPersonDataStore | PromiseLike<States.IPersonDataStore>) => void, 
    dbError: (error: any) => void) {
    const filterUserDiscussionThreadNotification = event?.detail?.configuration?.companion?.headerSettings?.enabledChat;

    initUserDataStore(userData);
    Notifications.getNotifications(event.eventid, userData.userId).then((notifsdata) => {
        let notifs = notifsdata.notifications.data;
        const messages = notifsdata.notifications.data?.filter((d) => d.notificationType === "NewDiscussionThreadMessage");

        if (notifs?.length && filterUserDiscussionThreadNotification) {
            notifs = notifs.filter((d) => d.notificationType !== "NewDiscussionThreadMessage" || d.exhibitorId);
        }
        
        userData.unreadNotifications = Notifications.getUnreadNotifications(notifs);
        userData.unreadMessages = Notifications.getUnreadNotifications(messages);
    }).then(() => {
        dbComplete(userData);
    }, dbError);
}

function newUserData(eventid: string, userid: string, options?: any) {
    if (!userid) {
        console.warn("userid is null in user db name");
        // eslint-disable-next-line no-debugger
        // debugger;
    }
    const db = getDb(eventid + '-' + userid, options);

    return <States.IPersonDataStore>{
        db: db,
        unreadNotifications: 0,
        eventId: eventid,
        userId: userid,
        ready: null,
        updates: {} as any,
        hasData: false,
        syncInProgess: false,
        userDetail: null,
        lastsyncs: null,
        favoriteSessions: null,
        favoriteExhibitors: null,
        favoriteExhibitorOfferings: null,
        favoriteEventMessages: null,
        favoriteEventThemeMessages: null,
        favoriteSessionMessages: null,
        favoriteEventThemes: null,
        registeredSessions: null,
        favoriteThemes: null,
        discussionThreads: null,
        sendedContactRequests: null,
        receivedContactRequests: null,
        meetingfeedbacks: null,
        moderators: null,
        userleads: null,
        meetings: null,
        // recentMessages : null,
        contactRecommandations: null,
        unreadMessages: 0,
        savingPromise: Promise.resolve(),
        save() {
            const promise = this.savingPromise.then(() => {
                return this.db.saveDatabase();
            });

            const safePromise = promise.then(null, () => { });

            this.savingPromise = safePromise;
            const cleanup = () => {
                if (this.savingPromise === safePromise) {
                    this.savingPromise = Promise.resolve();
                }
            };

            safePromise.then(cleanup);

            return promise;
        }
    };
}

function initUserDataStore(eData: States.IPersonDataStore) {
    const eventData = eData;
    // eslint-disable-next-line @typescript-eslint/ban-types
    const getDbCollection = function coll<T extends object>(name, options) {
        let col = eventData.db.getCollection<T>(name);
        if (!col) col = eventData.db.addCollection<T>(name, options);

        return col;
    };

    eventData.lastsyncs = getDbCollection<Entities.ILastSync>('lastsyncs', { unique: ['key'] });
    eventData.userDetail = getDbCollection<Entities.IAppUser>('userdetail', { unique: ['id'] });
    eventData.registeredSessions = getDbCollection<Entities.ISessionPerson>('registeredsessions', {});
    eventData.favoriteExhibitors = getDbCollection<States.IUserFavoriteExhibitor>(
        'favoriteexhibitors', { unique: ['exhibitorId'] }
    );
    eventData.favoriteExhibitorOfferings = getDbCollection<States.IUserFavoriteExhibitorOffering>(
        'favoriteexhibitorofferings', { unique: ['exhibitorOfferingId'] }
    );
    eventData.favoriteEventThemes = getDbCollection<States.IUserFavoriteEventTheme>(
        'favoriteeventthemes', { unique: ['eventThemeId'] }
    );
    eventData.favoriteEventMessages = getDbCollection<Entities.IUserFavorite>('favoriteeventmessages', {});
    eventData.favoriteSessionMessages = getDbCollection<Entities.IUserFavorite>('favoritesessionmessages', {});
    eventData.discussionThreads = getDbCollection<Entities.IDiscussionThreadWrapper>('discussionthreads', { unique: ['id'] });
    eventData.receivedContactRequests = getDbCollection<Entities.IContactRequest>(
        'receivedcontactrequests', { unique: ['personId'] }
    );
    eventData.sendedContactRequests = getDbCollection<Entities.IContactRequest>(
        'sendedcontactrequests', { unique: ['targetPersonId'] }
    );
    eventData.meetings = getDbCollection<Entities.IMeeting>('meetings', { unique: ['id'] });
    eventData.contactRecommandations = getDbCollection<Entities.IContactRecommandation>(
        'contactrecommandations', { unique: ['id'] }
    );
    eventData.userleads = getDbCollection<States.IUserLeads>('leads', { unique: ['id'] });
    eventData.meetingfeedbacks = getDbCollection<Entities.IMeetingFeedback>('meetingfeedbacks', { unique: ['id'] });
    eventData.moderators = getDbCollection<Entities.IModerator>('moderators', { unique: ['id'] });

    if (eventData.userDetail.count() > 0) {
        eventData.hasData = true;
    }
}
