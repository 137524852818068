import { States } from "@@services/services";
import { appSplashActions } from '@@services/splashservice';
import { eventModule } from "@@routes/appmodules";
import { clearLastSync } from "@@data/globalsync";

function setEventReady(dispatch, getState: () => States.IAppState) {
    const state = getState();
    if (!(state.event && state.event.eventReady)) {
        dispatch({ type: 'EVENT_ISREADY' });
    }
}

export function eventCheckForUser(dispatch, getState: () => States.IAppState) {
    const state = getState();
    const eventdetail = state.event && state.event.detail;
    const eventconf = eventdetail && eventdetail.configuration;
    const isLockedEvent = eventconf?.companion?.requireAuthenticationForContent;
    const isValidUser = state?.user?.currentUser?.detail?.isRegistered;

    if (isLockedEvent) {
        clearLastSync();
        appSplashActions.registerService("usereventsync", {
            hasError: false,
            progress: 0,
            message: "",
            onRetry: () => {
                return eventCheckForUser(dispatch, getState);
            }
        })(dispatch, getState);

        if (isValidUser) {
            return new Promise((resolve) => {
                return eventModule().then((mod) => {
                    return mod.syncEventData(
                        state.event.requestManagers,
                        "usereventsync",
                        state.event.eventid,
                        state.event.data,
                        false,
                        false,
                        dispatch,
                        getState
                    ).then(() => {
                        setEventReady(dispatch, getState);
                        setTimeout(() => {
                            appSplashActions.unregisterService("usereventsync")(dispatch, getState);
                            // userBootstrapActions.bootstrapCurrentUser()(dispatch, getState);
                        }, 100);
                    }).then(resolve, () => {
                        appSplashActions.progress("usereventsync", {
                            hasError: true,
                            message: "sync.error",
                            progress: null
                        })(dispatch);
                    });
                });
            });
        }

        if (state.user && state.user.checked) {
            setEventReady(dispatch, getState);
            setTimeout(() => {
                appSplashActions.unregisterService("usereventsync")(dispatch, getState);
            }, 100);
        }
    }

    return Promise.resolve();
}
